import Mixin from '@ember/object/mixin'
import { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import { createYearsList } from 'min-side/utils/date-ranges'
import ENV from 'min-side/config/environment'
import query from 'min-side/graphql/queries/playbacks'
import queryAuthenticated from 'min-side/graphql/queries/playbacks-authenticated'

const YEARS_TO_SHOW = 3

export default Mixin.create({
  intl: service(),
  flashMessages: service(),
  ajaxRequests: service(),
  apollo: queryManager(),
  queryParams: {
    after: { refreshModel: true },
    sort: { refreshModel: true },
    direction: { refreshModel: true },
    term: { refreshModel: true },
    year: { refreshModel: true },
    filters: { refreshModel: true },
    sector: { refreshModel: true }
  },

  actions: {
    // If we are on the same page (just changing a search parameter) then do not
    // show the loading mask (return false).
    loading(transition) {
      if (transition && transition.from && transition.from.name === this.routeName) {
        return false
      }
      return true
    },
    onOrderByChange(sort, direction) {
      this.transitionTo({ queryParams: { sort, direction, after: null } })
    }
  },

  _applyFilters(query, filters) {
    let newQuery = query

    if (filters && filters.length > 0) {
      if (!newQuery || newQuery === '*') {
        newQuery = ''
      }

      filters.forEach(filter => {
        const negative = filter.isNegative ? '!' : ''
        newQuery = `${newQuery} ${filter.field}:${negative}"${filter.value}"`
      })
    }
    return newQuery.trim()
  },

  async _getPlaybacks(term, filters, after, sort, direction, year, owner_id, sector) {
    this.controllerFor(this.routeName).set('isLoading', true)
    let years
    const order_by = []
    if (sort && direction) {
      order_by.push({
        property: sort,
        direction
      })
    }
    if (year) {
      years = [parseInt(year, 10)]
    } else {
      years = createYearsList(YEARS_TO_SHOW, 1)
    }

    const include_person = false

    const parsedFilters = this.controllerFor(this.routeName)._parseFilters(filters)
    const variables = {
      query: this._applyFilters(term, parsedFilters),
      first: this.controllerFor(this.routeName).get('first'),
      after,
      years,
      order_by,
      include_person,
      sector,
      owner_id
    }

    let options = {
      query,
      variables,
      fetchPolicy: 'network-only',
      context: {
        uri: `${ENV.apollo.publicApiURL}`
      }
    }
    // when logged in, using the regular graphql api and use a slightly different query with more fields
    if (this.get('authenticated')) {
      options = {
        query: queryAuthenticated,
        variables,
        fetchPolicy: 'network-only'
      }
    }

    if (term || parsedFilters.length) {
      const results = await this.apollo.query(options).catch(() => {
        this.flashMessages.error(this.intl.t('flash.http_codes.other'))
        // return a similar structure to what the real response would look like
        return { public_playbacks_aggregates: { edges: [] } }
      })

      this.controllerFor(this.routeName).set('isLoading', false)
      return results.public_playbacks_aggregates
    }
    this.controllerFor(this.routeName).set('isLoading', false)
    return []
  }
})
