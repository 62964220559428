import Component from '@ember/component'
import { computed, observer, set } from '@ember/object'
import { inject } from '@ember/service'

import { createYearsList } from 'min-side/utils/date-ranges'

const YEARS_TO_SHOW = 3

export default Component.extend({
  profile: inject(),
  tagName: '',
  possibleYears: createYearsList(YEARS_TO_SHOW, 1)
    .reverse()
    .map(y => {
      return String(y)
    }),
  recordings: [],

  protectedFilter: null,
  rightsholderFilter: { option: 'with', noRightsholder: null },
  performerFilter: { option: 'with', noPerformer: null },
  isProtectionOpen: false,
  isRightsholderOpen: false,
  isPerformerOpen: false,

  isPerformer: computed('profile.activeProfile.profile', function () {
    return this.profile.activeProfile.profile === 'performer'
  }),

  protectedFilterObserver: observer('protectedFilter', function () {
    this.get('onAddFilter')('protected', this.get('protectedFilter'))
    this.set('isProtectionOpen', false)
    this.set('protectedFilter', null)
  }),

  noRightsholderObserver: observer('rightsholderFilter.noRightsholder', function () {
    if (this.get('rightsholderFilter.noRightsholder') === 'true') {
      this.get('onAddFilter')('producer_name', '!*')
      this.set('isRightsholderOpen', false)
      this.set('rightsholderFilter.noRightsholder', null)
    }
  }),

  hasRightsholderFilter: computed('filters', function () {
    return this.get('filters').find(filter => {
      return filter.field === 'producer_name'
    })
  }),

  noPerformerObserver: observer('performerFilter.noPerformer', function () {
    if (this.get('performerFilter.noPerformer') === 'true') {
      this.get('onAddFilter')('performer_name', '!*')
      this.set('isPerformerOpen', false)
      this.set('performerFilter.noPerformer', null)
    }
  }),

  hasPerformerFilter: computed('filters', function () {
    return this.get('filters').find(filter => {
      return filter.field === 'performer_name'
    })
  }),

  hasProtectionFilter: computed('filters', function () {
    return this.get('filters').find(filter => {
      return filter.field === 'protected'
    })
  }),

  singleClaimMode: computed('isPerformer', function () {
    if (this.isPerformer) {
      return true
    }
  }),

  selectedRecordings: computed('model.edges.@each.checked', function () {
    return this.get('model.edges').filter(recording => {
      return recording.checked
    })
  }),

  actions: {
    recordingSelect(value, event) {
      if (event.target.checked) {
        set(value, 'checked', true)
      } else {
        set(value, 'checked', false)
      }
    },
    submit() {
      this.get('onAddToClaim')(this.recordings, this.get('model.sector'))
      this.recordings = []
    },
    async add() {
      const recordings = []
      this.get('selectedRecordings').forEach(recording => {
        const playbacks = recording.node.playbacks.map(playback => {
          return {
            local_id: playback.local_id,
            broadcaster_id: playback.broadcaster_id
          }
        })

        recordings.push({
          title: recording.node.title,
          main_artist: recording.node.main_artist,
          isrc: recording.node.isrc,
          album: recording.node.album,
          label_name: recording.node.label_name,
          catalog_number: recording.node.catalog_number,
          release_year: recording.node.release_year,
          production_year: recording.node.production_year,
          duration_in_seconds: recording.node.duration_in_seconds,
          producer_names: recording.node.producer_names,
          playbacks
        })
      })

      await this.get('onAddToClaim')(recordings, this.get('model.sector'))
      this.get('selectedRecordings').forEach(recording => {
        set(recording, 'checked', false)
      })
    },
    toggleAll(event) {
      if (event.target.checked) {
        this.get('model.edges').forEach(recording => {
          if (recording.checked || recording.node.exists) {
            return
          }
          set(recording, 'checked', true)
        })
      } else {
        this.get('model.edges').forEach(recording => {
          set(recording, 'checked', false)
        })
      }
    },
    toggleProtectionFilter() {
      this.toggleProperty('isProtectionOpen')

      if (this.get('isProtectionOpen')) {
        this.set('isRightsholderOpen', false)
      }
    },
    toggleRightsholderFilter() {
      this.toggleProperty('isRightsholderOpen')

      if (this.get('isRightsholderOpen')) {
        this.set('isProtectionOpen', false)
      }
    },
    togglePerformerFilter() {
      this.toggleProperty('isPerformerOpen')

      if (this.get('isPerformerOpen')) {
        this.set('isProtectionOpen', false)
      }
    },
    closeProtectionFilter(_event) {
      this.set('isProtectionOpen', false)
    },
    closeRightsholderFilter(_event) {
      this.set('isRightsholderOpen', false)
    },
    closePerformerFilter(_event) {
      this.set('isPerformerOpen', false)
    },
    applyRightsholderFilter(value, event) {
      switch (event.key) {
        case 'Enter': {
          const producerName =
            this.get('rightsholderFilter.option') === 'without' ? `!${value}` : value

          this.get('onAddFilter')('producer_name', producerName)
          this.set('isRightsholderOpen', false)
          this.set('rightsholderFilter', { option: 'with' })
          break
        }
      }
    },
    applyPerformerFilter(value, event) {
      switch (event.key) {
        case 'Enter': {
          const performerName =
            this.get('performerFilter.option') === 'without' ? `!${value}` : value

          this.get('onAddFilter')('performer_name', performerName)
          this.set('isPerformerOpen', false)
          this.set('performerFilter', { option: 'with' })
          break
        }
      }
    },
    view() {
      this.get('viewClaim')()
    }
  }
})
